import { CloseButton } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';

import styles from './Drawer.module.scss';
import { Props } from './Drawer.types';

const Drawer = React.forwardRef<PopupActions, Props>(
  ({ icon, iconClassName, crossClassName, title, titleClassName, children, onClose }: Props) => {
    return (
      <>
        <div
          className={styles.overlay}
          onClick={() => {
            onClose();
          }}
        />
        <div className={styles.container}>
          <div className={classnames(styles.icon, iconClassName)}>{icon}</div>

          <CloseButton
            onClick={() => {
              onClose();
            }}
            className={classnames(styles.cross, crossClassName)}
          />

          <div className={classnames(styles.title, titleClassName)}>{title}</div>
          <div className={styles.separator} />
          <div className={styles.content}>{children}</div>
        </div>
      </>
    );
  }
);

export default Drawer;
