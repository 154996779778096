import {
  EDPProd,
  EDPMobileIcon,
  PigeMobileIcon,
  PigeUATMobile,
  PuzzleUATMobile,
  TDPUATMobile,
  BDPMobile,
  SatisfactionUATMobile,
  BDPUATMobile,
  BDP,
  PuzzleMobileIcon,
  TDPMobileIcon,
  SatisfactionMobile,
  FlashMobileIcon,
  RLUATMobile,
  RLMobileIcon,
  FlashProd,
  PuzzleProd,
  FlashUATMobile,
  EDPUATMobile,
  RecrutLiveProd,
  SatisfactionProd,
  TDPProd,
  EDPUat,
  FlashUat,
  PuzzleUat,
  RecrutLiveUat,
  SatisfactionUat,
  TDPUat,
  ITProd,
  ITUat,
  ITMobileProd,
  ITMobileUat,
  PigeProd,
  PigeUat,
  ScopeProd,
  ScopeUat,
  POMIcon,
  POMUatIcon,
  POMMobileIcon,
  POMUatMobileIcon,
  CustomRandyProd,
  CustomRandyMobileIcon,
  CustomRandyUat,
  CustomRandyUATMobile,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';

import styles from './AppsDetailsValues.module.scss';

export enum RANDSTAD_APP {
  EDP = 'edp',
  FLASH = 'flash',
  RECRUTLIVE = "recrut'live",
  PUZZLE = 'puzzle',
  SATISFACTION = 'satisfaction',
  TDP = 'tdp',
  PIGE = 'pige',
  SCOPE = 'scope',
  IT = 'aide IT',
  BDP = 'bdp',
  POM = 'pom',
  CUSTOM_RANDY = 'randy personnalisé',
}

export enum RANDSTAD_APP_CATEGORY {
  ADMINISTRATIVE = 'administratif',
  PROSPECTION = 'prospection',
  PRODUCTION = 'production',
  PROCEDURE = 'démarche',
  SECTOR = 'secteur',
}

export interface AppData {
  icon: {
    prod: React.ReactNode;
    uat: React.ReactNode;
    mobileProd: React.ReactNode;
    mobileUat: React.ReactNode;
  };
  name: RANDSTAD_APP;
  fullName: string;
  category: RANDSTAD_APP_CATEGORY;
  link: { prod: string; uat: string };
  iconStyle: string;
}

export const apps: AppData[] = [
  {
    icon: {
      prod: <POMIcon />,
      uat: <POMUatIcon />,
      mobileProd: <POMMobileIcon />,
      mobileUat: <POMUatMobileIcon />,
    },
    name: RANDSTAD_APP.POM,
    fullName: 'portail mobilité',
    category: RANDSTAD_APP_CATEGORY.ADMINISTRATIVE,
    link: {
      prod: 'https://portailmobilite.grouperandstad.fr',
      uat: 'https://recette.portailmobilite.grouperandstad.fr',
    },
    iconStyle: styles.blueColor,
  },
  {
    icon: {
      prod: <EDPProd />,
      uat: <EDPUat />,
      mobileProd: <EDPMobileIcon />,
      mobileUat: <EDPUATMobile />,
    },
    name: RANDSTAD_APP.EDP,
    fullName: 'étude de poste',
    category: RANDSTAD_APP_CATEGORY.PRODUCTION,
    link: {
      prod: 'https://etudedeposte.grouperandstad.fr',
      uat: 'https://recette.etudedeposte.grouperandstad.fr',
    },
    iconStyle: styles.navyColor,
  },
  {
    icon: {
      prod: <FlashProd />,
      uat: <FlashUat />,
      mobileProd: <FlashMobileIcon />,
      mobileUat: <FlashUATMobile />,
    },
    name: RANDSTAD_APP.FLASH,
    fullName: 'flash',
    category: RANDSTAD_APP_CATEGORY.ADMINISTRATIVE,
    link: {
      prod: 'https://flash.grouperandstad.fr',
      uat: 'https://recette.flash.grouperandstad.fr',
    },
    iconStyle: styles.warmredColor,
  },
  {
    icon: {
      prod: <RecrutLiveProd />,
      uat: <RecrutLiveUat />,
      mobileProd: <RLMobileIcon />,
      mobileUat: <RLUATMobile />,
    },
    name: RANDSTAD_APP.RECRUTLIVE,
    fullName: "recrut'live",
    category: RANDSTAD_APP_CATEGORY.PROSPECTION,
    link: {
      prod: 'https://recrutlive.grouperandstad.fr',
      uat: 'https://recette.recrutlive.grouperandstad.fr',
    },
    iconStyle: styles.navyColor,
  },
  {
    icon: {
      prod: <PuzzleProd />,
      uat: <PuzzleUat />,
      mobileProd: <PuzzleMobileIcon />,
      mobileUat: <PuzzleUATMobile />,
    },
    name: RANDSTAD_APP.PUZZLE,
    fullName: 'puzzle',
    category: RANDSTAD_APP_CATEGORY.PROSPECTION,
    link: {
      prod: 'https://puzzleweb.grouperandstad.fr',
      uat: 'https://recette.puzzleweb.grouperandstad.fr',
    },
    iconStyle: styles.navyColor,
  },
  {
    icon: {
      prod: <SatisfactionProd />,
      uat: <SatisfactionUat />,
      mobileProd: <SatisfactionMobile />,
      mobileUat: <SatisfactionUATMobile />,
    },
    name: RANDSTAD_APP.SATISFACTION,
    fullName: 'satisfaction',
    category: RANDSTAD_APP_CATEGORY.PROCEDURE,
    link: {
      prod: 'https://satisfaction.grouperandstad.fr',
      uat: 'https://recette.satisfaction.grouperandstad.fr',
    },
    iconStyle: styles.blueColor,
  },
  {
    icon: {
      prod: <TDPProd />,
      uat: <TDPUat />,
      mobileProd: <TDPMobileIcon />,
      mobileUat: <TDPUATMobile />,
    },
    name: RANDSTAD_APP.TDP,
    fullName: 'tour de prod',
    category: RANDSTAD_APP_CATEGORY.PRODUCTION,
    link: {
      prod: 'https://tourdeprod.grouperandstad.fr',
      uat: 'https://recette.tourdeprod.grouperandstad.fr',
    },
    iconStyle: styles.yellowColor,
  },
  {
    icon: {
      prod: <PigeProd />,
      uat: <PigeUat />,
      mobileProd: <PigeMobileIcon />,
      mobileUat: <PigeUATMobile />,
    },
    name: RANDSTAD_APP.PIGE,
    fullName: 'la pige',
    category: RANDSTAD_APP_CATEGORY.SECTOR,
    link: {
      prod: 'https://pige.grouperandstad.fr',
      uat: 'https://recette.pige.grouperandstad.fr',
    },
    iconStyle: styles.blueColor,
  },
  {
    icon: {
      prod: <BDP />,
      uat: <BDP />,
      mobileProd: <BDPMobile />,
      mobileUat: <BDPUATMobile />,
    },
    name: RANDSTAD_APP.BDP,
    fullName: 'brief de poste',
    category: RANDSTAD_APP_CATEGORY.PRODUCTION,
    link: {
      prod: 'https://briefdeposte.grouperandstad.fr',
      uat: 'https://recette.briefdeposte.grouperandstad.fr',
    },
    iconStyle: styles.warmredColor,
  },
  {
    icon: {
      prod: <ScopeProd />,
      uat: <ScopeUat />,
      mobileProd: <ScopeProd />,
      mobileUat: <ScopeUat />,
    },
    name: RANDSTAD_APP.SCOPE,
    fullName: 'scope',
    category: RANDSTAD_APP_CATEGORY.PROSPECTION,
    link: {
      prod: 'https://scoperandstad.page.link/u9DC',
      uat: 'https://scoperandstad.page.link/gnpR',
    },
    iconStyle: styles.greenishColor,
  },
  {
    icon: {
      prod: <ITProd />,
      uat: <ITUat />,
      mobileProd: <ITMobileProd />,
      mobileUat: <ITMobileUat />,
    },
    name: RANDSTAD_APP.IT,
    fullName: 'aide IT',
    category: RANDSTAD_APP_CATEGORY.SECTOR,
    link: {
      prod: 'https://randstad.service-now.com/',
      uat: 'https://randstad.service-now.com/',
    },
    iconStyle: styles.navyColor,
  },
  {
    icon: {
      prod: <CustomRandyProd />,
      uat: <CustomRandyUat />,
      mobileProd: <CustomRandyMobileIcon />,
      mobileUat: <CustomRandyUATMobile />,
    },
    name: RANDSTAD_APP.CUSTOM_RANDY,
    fullName: 'randy personnalisé',
    category: RANDSTAD_APP_CATEGORY.PROSPECTION,
    link: {
      prod: 'https://portailmobilite.grouperandstad.fr/custom-randy',
      uat: 'https://recette.portailmobilite.grouperandstad.fr/custom-randy',
    },
    iconStyle: styles.blueColor,
  },
];
